const articledata = [
    {
        name:'number-one',
        title:'My First Article',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque rhoncus augue faucibus, interdum magna eu, placerat ligula. Duis malesuada turpis eu dui faucibus aliquam. In bibendum molestie lacus, nec scelerisque urna pulvinar quis. Aliquam varius placerat lacus ut malesuada. Phasellus finibus leo et felis ultrices iaculis. Fusce sollicitudin maximus ante in lobortis. Etiam fringilla imperdiet ultricies. Phasellus odio magna, vehicula at pellentesque a, suscipit a diam. Sed et pellentesque dolor. Maecenas rhoncus, nisi id luctus tristique, risus tortor maximus nisl, sit amet maximus neque tellus quis ipsum. In hac habitasse platea dictumst. Phasellus blandit lacus at lacus sodales, non mollis lectus fermentum.'
        ],
        content2: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque rhoncus augue faucibus, interdum magna eu, placerat ligula. Duis malesuada turpis eu dui faucibus aliquam. In bibendum molestie lacus, nec scelerisque urna pulvinar quis. Aliquam varius placerat lacus ut malesuada. Phasellus finibus leo et felis ultrices iaculis. Fusce sollicitudin maximus ante in lobortis. Etiam fringilla imperdiet ultricies. Phasellus odio magna, vehicula at pellentesque a, suscipit a diam. Sed et pellentesque dolor. Maecenas rhoncus, nisi id luctus tristique, risus tortor maximus nisl, sit amet maximus neque tellus quis ipsum. In hac habitasse platea dictumst. Phasellus blandit lacus at lacus sodales, non mollis lectus fermentum.'
        ],
        content3: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque rhoncus augue faucibus, interdum magna eu, placerat ligula. Duis malesuada turpis eu dui faucibus aliquam. In bibendum molestie lacus, nec scelerisque urna pulvinar quis. Aliquam varius placerat lacus ut malesuada. Phasellus finibus leo et felis ultrices iaculis. Fusce sollicitudin maximus ante in lobortis. Etiam fringilla imperdiet ultricies. Phasellus odio magna, vehicula at pellentesque a, suscipit a diam. Sed et pellentesque dolor. Maecenas rhoncus, nisi id luctus tristique, risus tortor maximus nisl, sit amet maximus neque tellus quis ipsum. In hac habitasse platea dictumst. Phasellus blandit lacus at lacus sodales, non mollis lectus fermentum.'
        ]
    },
    {
        name:'projects',
        title:'My projects',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque rhoncus augue faucibus, interdum magna eu, placerat ligula. Duis malesuada turpis eu dui faucibus aliquam. In bibendum molestie lacus, nec scelerisque urna pulvinar quis. Aliquam varius placerat lacus ut malesuada. Phasellus finibus leo et felis ultrices iaculis. Fusce sollicitudin maximus ante in lobortis. Etiam fringilla imperdiet ultricies. Phasellus odio magna, vehicula at pellentesque a, suscipit a diam. Sed et pellentesque dolor. Maecenas rhoncus, nisi id luctus tristique, risus tortor maximus nisl, sit amet maximus neque tellus quis ipsum. In hac habitasse platea dictumst. Phasellus blandit lacus at lacus sodales, non mollis lectus fermentum.'
        ],
        content2: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque rhoncus augue faucibus, interdum magna eu, placerat ligula. Duis malesuada turpis eu dui faucibus aliquam. In bibendum molestie lacus, nec scelerisque urna pulvinar quis. Aliquam varius placerat lacus ut malesuada. Phasellus finibus leo et felis ultrices iaculis. Fusce sollicitudin maximus ante in lobortis. Etiam fringilla imperdiet ultricies. Phasellus odio magna, vehicula at pellentesque a, suscipit a diam. Sed et pellentesque dolor. Maecenas rhoncus, nisi id luctus tristique, risus tortor maximus nisl, sit amet maximus neque tellus quis ipsum. In hac habitasse platea dictumst. Phasellus blandit lacus at lacus sodales, non mollis lectus fermentum.'
        ],
        content3: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque rhoncus augue faucibus, interdum magna eu, placerat ligula. Duis malesuada turpis eu dui faucibus aliquam. In bibendum molestie lacus, nec scelerisque urna pulvinar quis. Aliquam varius placerat lacus ut malesuada. Phasellus finibus leo et felis ultrices iaculis. Fusce sollicitudin maximus ante in lobortis. Etiam fringilla imperdiet ultricies. Phasellus odio magna, vehicula at pellentesque a, suscipit a diam. Sed et pellentesque dolor. Maecenas rhoncus, nisi id luctus tristique, risus tortor maximus nisl, sit amet maximus neque tellus quis ipsum. In hac habitasse platea dictumst. Phasellus blandit lacus at lacus sodales, non mollis lectus fermentum.'
        ]
    }
];
export default articledata;