import { useParams } from "react-router-dom";
import articledata from "./articledata";
import '../App.css';

const ArticlePage = () => {
    const {articleId} = useParams();
    
    const article = articledata.find(articledata => articledata.name === articleId);
    return (
        <>
        <h1>{article.title}</h1>
        {article.content.map(paragraph =>(
            <p class="article-conent">{paragraph}</p>
        ))}
            {article.content2.map(paragraph =>(
            <p class="article-conent">{paragraph}</p>
        ))}
            {article.content3.map(paragraph =>(
            <p class="article-conent">{paragraph}</p>
        ))}
   
</>
   
    );
}
export default ArticlePage;