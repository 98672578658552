import './App.css';
import { Link } from 'react-router-dom';
const NavBar = () => {
    return (
<nav class="navbar">
    <ul>
        <Link class="navlink" to="/">Home</Link>
        <Link class="navlink" to="/about">About</Link>
        <Link class="navlink" to="/articles">Articles</Link>

    </ul>
    
</nav>
    )
}
export default NavBar;